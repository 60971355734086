import { LoginStrings } from '@kvika/audur-strings';
import * as React from 'react';
import styled from 'styled-components';
import { GridRem } from '../../../dls/Styleguide';
import { StyledElectronicIdText, StyledESIMInfoContainer } from '../../../styles/PageStyles/LoginPageStyles';
import { AudurTextInput } from '../../common';

export enum InputTypes {
  Phone = 'phone',
  SSN = 'ssn',
}

type Props = {
  label: string;
  onChange: (text: string) => void;
  isLoading: boolean;
  value: string;
  onLoginStart(): void;
  inputType?: InputTypes;
  verificationCode?: string;
};

const StyledTextInput = styled(AudurTextInput)`
  margin: ${GridRem.Gridx32} 0;
`;

const AuthForm = React.forwardRef(
  (
    { label, onChange, isLoading, value, inputType, verificationCode, onLoginStart }: Props,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const renderLoginInstructions = () => {
      if (isLoading) {
        if (inputType === InputTypes.Phone) {
          return (
            <StyledElectronicIdText>
              {LoginStrings.ElectronicIdText}
              <p>Öryggisnúmer: {verificationCode}</p>
            </StyledElectronicIdText>
          );
        }
        return (
          <StyledESIMInfoContainer>
            <p>{LoginStrings.ESIMText}</p>
            <p>
              {LoginStrings.SecurityNumber}: <b>{verificationCode}</b>
            </p>
          </StyledESIMInfoContainer>
        );
      }
      return null;
    };

    return (
      <div style={{ width: '100%' }}>
        <StyledTextInput
          id={label}
          label={label}
          onChange={(value) => onChange(value)}
          value={value}
          isPhoneNumber={inputType === InputTypes.Phone}
          isSSN={inputType === InputTypes.SSN}
          name={inputType === InputTypes.Phone ? 'loginPhone' : 'loginSSN'}
          width="100%"
          isDisabled={isLoading}
          ref={ref}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              onLoginStart();
            }
          }}
        />
        {renderLoginInstructions()}
      </div>
    );
  }
);

AuthForm.displayName = 'AuthForm';

export default AuthForm;
